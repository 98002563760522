<template>
  <v-card max-width="40rem" class="mx-auto">
    <v-card-title>{{ $t("Home") }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-card outlined :to="{ name: 'CameraCapture' }" class="pa-5">
            <v-row justify="center">
              <v-col cols="auto">
                <v-icon size="80">mdi-camera</v-icon>
              </v-col>
            </v-row>
            <v-row dense justify="center">
              <v-col cols="auto">{{ $t("Camera") }}</v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card outlined :to="{ name: 'Driving' }" class="pa-5">
            <v-row justify="center">
              <v-col cols="auto">
                <v-icon size="80">mdi-car</v-icon>
              </v-col>
            </v-row>
            <v-row dense justify="center">
              <v-col cols="auto">{{ $t("Driving") }}</v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
}
</script>
