<template>
  <AppTemplate :options="options">
    <template v-slot:header>
      <v-btn color="#c00000" icon v-if="!$store.state.coordinates">
        <v-icon>mdi-crosshairs-question</v-icon>
      </v-btn>

      <v-btn v-else-if="!$store.state.coordinates.heading" color="#c00000" icon>
        <v-icon>mdi-compass-off</v-icon>
      </v-btn>

      <v-btn
        @click="$store.commit('geolocationStart')"
        :disabled="!!$store.state.watchId"
        icon
      >
        <v-icon>mdi-map-marker</v-icon>
      </v-btn>
      <v-btn
        @click="$store.commit('geolocationStop')"
        :disabled="!$store.state.watchId"
        icon
      >
        <v-icon>mdi-map-marker-off</v-icon>
      </v-btn>
    </template>
    <template v-slot:nav>
      <v-list dense nav>
        <v-list-item>
          <LocaleSelector />
        </v-list-item>
        <v-divider />

        <v-list-item
          v-for="(item, index) in nav"
          :key="`nav_item_${index}`"
          :to="item.to"
          exact
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </AppTemplate>
</template>

<script>
import AppTemplate from "@moreillon/vue_application_template_vuetify"

import LocaleSelector from "./components/LocaleSelector.vue"

const { VUE_APP_LOGIN_URL, VUE_APP_IDENTIFICATION_URL } = process.env

export default {
  name: "App",
  components: {
    AppTemplate,
    LocaleSelector,
  },
  data() {
    return {
      options: {
        title: "Snow",
        login_url: VUE_APP_LOGIN_URL,
        identification_url: VUE_APP_IDENTIFICATION_URL,
        header_logo: require("@/assets/jtekt_logo_negative.jpg"),
        authentication_logo: require("@/assets/jtekt_logo.jpg"),
        colors: { app_bar: "#000" },
        author: "JTEKT Corporation",
      },
    }
  },
  mounted() {
    this.$store.commit("geolocationStart")
  },
  computed: {
    nav() {
      return [
        { title: this.$t("Home"), icon: "mdi-home", to: { name: "Home" } },
        {
          title: this.$t("Camera"),
          icon: "mdi-camera",
          to: { name: "CameraCapture" },
        },
        { title: this.$t("Driving"), icon: "mdi-car", to: { name: "Driving" } },
        {
          title: this.$t("Images"),
          icon: "mdi-folder-multiple-image",
          to: { name: "Items", query: { limit: 10, skip: 0 } },
        },
        {
          title: this.$t("Settings"),
          icon: "mdi-cogs",
          to: { name: "Settings" },
        },

        {
          title: this.$t("About"),
          icon: "mdi-information-outline",
          to: { name: "About" },
        },
      ]
    },
  },
}
</script>

<style></style>

<style>
.header_logo {
  border-right: 1px solid white;
}
</style>
