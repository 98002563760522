import Vue from "vue"
import Vuex from "vuex"

import defaultSettings from "@/settings"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    coordinates: null,
    error: null,
    watchId: 0,
    settings: {
      ...defaultSettings,
      ...JSON.parse(localStorage.getItem("settings")),
    },
  },
  mutations: {
    geolocationStart(state) {
      const options = { enableHighAccuracy: true }

      const positionUpdateHandler = ({ coords }) => {
        // state.coordinates = coords

        // If no coord available (null or undefined, set store value to same)
        if (!coords) {
          state.coordinates = coords
          return
        }

        // eslint-disable-next-line no-unused-vars
        const { latitude, longitude, speed, accuracy, heading } = coords

        // Only update heading if speed is greater than 1 m/s
        if (speed > 1 || !state.coordinates) {
          state.coordinates = { latitude, longitude, speed, accuracy, heading }
        } else {
          state.coordinates = {
            ...state.coordinates,
            latitude,
            longitude,
            accuracy,
            speed,
          }
        }

        // // Dummy coordinates
        // state.coordinates = {
        //   latitude: 35.1400475,
        //   longitude: 136.8953036,
        //   heading: 10,
        //   accuracy: 3,
        // }
      }

      const positionErrorHandler = (error) => {
        this.geolocationStop()
        console.error(error)
        this.error = error
      }

      state.watchId = navigator.geolocation.watchPosition(
        positionUpdateHandler,
        positionErrorHandler,
        options
      )
    },
    geolocationStop(state) {
      navigator.geolocation.clearWatch(this.watchId)
      state.watchId = 0
    },
    updateSetting(state, newSettings) {
      state.settings = { ...state.settings, ...newSettings }
      const settingsStringified = JSON.stringify(state.settings)
      localStorage.setItem("settings", settingsStringified)
    },
  },
  actions: {},
  modules: {},
})
