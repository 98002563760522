<template>
  <v-dialog v-model="dialog" fullscreen>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-fullscreen</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar flat>
        <!-- TODO: Title probably not necessary -->
        <v-toolbar-title>{{ $t("Driving") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-fullscreen-exit</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider />
      <!-- Muting alarm because alarm comes from the parent component -->
      <ItemCard
        v-if="item"
        :itemId="item._id"
        :hideDescription="!showDescription"
        muteAlarm
        flat
      />
      <v-card-text v-else class="text-center text-h6 pt-6">
        {{ $t("No data available") }}
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import coordinateHelpers from "@/mixins/coordinateHelpers"
import ItemCard from "@/components/drive/ItemCard.vue"

export default {
  name: "DriveFullScreenDialog",
  props: {
    item: Object,
  },
  mixins: [coordinateHelpers],
  components: {
    ItemCard,
  },
  data() {
    return {
      dialog: false,
      showDescription: true,
    }
  },
}
</script>
