
import Dexie from 'dexie'


export const db = new Dexie('snowdb')

const stores = {
    images: '++_id, &file, time', // Primary key and indexed props
}

db.version(7).stores(stores)