export default {
  data() {
    return {
      settings: { ...this.$store.state.settings },
    }
  },
  watch: {
    settings: {
      handler() {
        this.$store.commit("updateSetting", this.settings)
      },
      deep: true,
    },
  },
}
