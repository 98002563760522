import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import i18n from './i18n'
import './registerServiceWorker'
import VueLayers from 'vuelayers'
// import 'vuelayers/dist/vuelayers.css' // needs css-loader (Vuelayers 0.12)
import 'vuelayers/lib/style.css' // needs css-loader (Vuelayers 0.11)

Vue.use(VueLayers, { dataProjection: 'EPSG:4326' })
// Vue.use(VueLayers)


axios.defaults.baseURL = process.env.VUE_APP_IMAGE_STORAGE_API_URL

Vue.use(VueAxios, axios)

Vue.config.productionTip = false



new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
