<template>
  <v-card-text>
    <v-row justify="center" align="center">
      <v-col cols="auto">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-col>
      <v-col cols="auto">
        {{ $t("Waiting for GPS") }}
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  name: "WaitingForGps",
}
</script>
