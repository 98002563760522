import { destination, getCoord, bearing, point, distance } from "@turf/turf"

export default {
  methods: {
    getPredictedPosition(origin) {
      const {
        latitude: originLatitude,
        longitude: originLongitude,
        heading,
        speed = 0, // [m/s]
      } = origin

      // Warning: cases without heading are not dealt with

      let bearing = heading
      if (bearing > 180) bearing -= 360

      const originFormatted = point([originLongitude, originLatitude])

      const { lookAheadCoefficient } = this.$store.state.settings.drive
      const distance = 0.001 * lookAheadCoefficient * speed // [km]

      const desinationPoint = destination(originFormatted, distance, bearing)

      const [destinationLongitude, destinationLatitude] =
        getCoord(desinationPoint)

      return {
        latitude: destinationLatitude,
        longitude: destinationLongitude,
      }
    },
    distanceBetweenCoordinates(p1, p2) {
      const { latitude: lat1, longitude: lon1 } = p1
      const { latitude: lat2, longitude: lon2 } = p2

      const point1 = point([lon1, lat1])
      const point2 = point([lon2, lat2])
      return distance(point1, point2, { units: "meters" })
    },
    bearingBetweenCoordinates(p1, p2) {
      const { latitude: lat1, longitude: lon1 } = p1
      const { latitude: lat2, longitude: lon2 } = p2

      const point1 = point([lon1, lat1])
      const point2 = point([lon2, lat2])

      // bearingは２点間の北を０としたときの角度を算出
      return bearing(point1, point2)
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180)
    },
    rad2deg(rad) {
      return rad * (180 / Math.PI)
    },
    latLonOffsetFromDistance(d, currentCoordinates) {
      // TODO: remove if not used
      const { latitude: lat } = currentCoordinates

      //Earth’s radius, sphere
      const R = 6378137

      // offsets in meters
      // Same nort and east
      const dn = d
      const de = d

      //Coordinate offsets in radians
      const dLat = dn / R
      const dLon = de / (R * Math.cos(this.deg2rad(lat)))

      return {
        latitudeOffset: this.rad2deg(dLat),
        longitudeOffset: this.rad2deg(dLon),
      }
    },
  },
}
