<!-- TODO: See if can be combined with ItemLocationMap.vue -->
<template>
  <vl-map
    :load-tiles-while-animating="true"
    :load-tiles-while-interacting="true"
    class="map"
  >
    <vl-view
      :zoom.sync="zoom"
      :center.sync="center"
      :rotation.sync="rotation"
    />

    <!-- Fixed overlay for map controls -->
    <v-card class="tools pa-2">
      <v-row justify="space-between" dense>
        <v-col cols="auto">
          <v-btn icon @click="centerMapCaptureLocation()">
            <v-icon>mdi-crosshairs-gps</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn icon @click="rotateMapToCaptureHeading()">
            <v-icon>mdi-rotate-left</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <vl-layer-tile id="osm">
      <vl-source-osm />
    </vl-layer-tile>

    <!-- Capture location -->
    <vl-feature>
      <vl-geom-point :coordinates="captureFormattedCoordinates" />
      <vl-style-box>
        <!-- Not rotating the icon because map is rotating instead -->
        <vl-style-icon
          :rotation="(Math.PI * captureCoordinates.heading) / 180.0 + rotation"
          src="/img/markers/marker_image_heading_variant.png"
          :scale="0.5"
          :anchor="[0.5, 0.5]"
        />
      </vl-style-box>
    </vl-feature>

    <!-- Accuracy -->
    <vl-feature v-if="captureCoordinates.accuracy">
      <vl-geom-circle
        :coordinates="captureFormattedCoordinates"
        :radius="captureCoordinates.accuracy"
      />
      <vl-style-box>
        <vl-style-fill color="#0000c011" />
        <vl-style-stroke color="#0000c044" />
      </vl-style-box>
    </vl-feature>

    <!-- Button to update position -->
    <v-btn icon @click="setNewPosition()" class="update-position-button">
      <v-icon>mdi-crosshairs</v-icon>
    </v-btn>
  </vl-map>
</template>

<script>
export default {
  name: "CapturedItemMap",
  props: {
    captureCoordinates: undefined,
  },
  data() {
    return {
      zoom: 17,
      center: [0, 0],
      rotation: 0,
    }
  },
  mounted() {
    this.center = this.captureFormattedCoordinates
  },
  methods: {
    centerMapCaptureLocation() {
      this.center = this.captureFormattedCoordinates
    },

    rotateMapToCaptureHeading() {
      // WARNING: This could result in negative values
      const captureHeadingRad =
        (Math.PI * this.captureCoordinates.heading) / 180.0

      // Rotate the map to the heading of the catpure
      // NOTE: Map needs to be rotated inversely to heading
      this.rotation = -captureHeadingRad
    },
    setNewPosition() {
      const [longitude, latitude] = this.center
      this.$emit("positionUpdate", { longitude, latitude })
    },
  },
  computed: {
    captureFormattedCoordinates() {
      if (!this.captureCoordinates) return null
      return [
        this.captureCoordinates.longitude,
        this.captureCoordinates.latitude,
      ]
    },
    computedCenter: {
      get() {
        return this.center
      },
      set(newVal) {
        this.$emit("update:center", newVal)
      },
    },
    computedRotation: {
      get() {
        return this.rotation
      },
      set(newVal) {
        this.$emit("update:rotation", newVal)
      },
    },
  },
}
</script>

<style scoped>
.map {
  position: relative;
  height: 400px;
}

.tools {
  position: absolute;
  bottom: 1.5em;
  right: 1em;
  z-index: 4;
}

.update-position-button {
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
