import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"
import CameraCapture from "../views/CameraCapture.vue"
import Driving from "../views/Driving.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/camera",
    name: "CameraCapture",
    component: CameraCapture,
  },
  {
    path: "/driving",
    name: "Driving",
    component: Driving,
  },
  {
    path: "/items",
    name: "Items",
    component: () => import("../views/Items.vue"),
  },
  {
    path: "/items/:_id",
    name: "Item",
    component: () => import("../views/Item.vue"),
  },
  {
    path: "/settings",
    name: "Settings",

    component: () => import("../views/Settings.vue"),
  },
  {
    path: "/about",
    name: "About",

    component: () => import("../views/About.vue"),
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
