// Imported in the store as default settings
export default {
  capture: {
    resizeCoefficient: 0.5,
    distanceBetweenContinuousCaptures: 10,
  },
  drive: {
    filterHeading: true,
    displayRadius: 10,
    maxMapItems: 50,
    lookAheadCoefficient: 1,
  },
}
