<template>
  <v-card :outlined="outlined" :flat="flat">
    <template v-if="item">
      <v-img :src="imageSrc" contain max-height="70vh" />
      <v-card-text>
        <v-row align="baseline" v-if="!hideDescription">
          <v-col cols="auto" class="text-h6 red--text">
            {{ item.data.description }}
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" v-if="coordinates">
            <v-icon left>mdi-map-marker-distance</v-icon>
            <span
              >{{
                Math.round(distanceBetweenCoordinates(item.data, coordinates))
              }}
              m</span
            >
          </v-col>
          <v-col cols="auto">
            <v-btn
              icon
              :color="item.data.alarm ? 'red':''"
              @click="switchAlarm"
            >
              <v-icon>mdi-alarm-light</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </template>
  </v-card>
</template>
<script>
import coordinateHelpers from "@/mixins/coordinateHelpers"
import { db } from "@/idb"

export default {
  name: "ItemCard",
  mixins: [coordinateHelpers],

  props: {
    itemId: String,
    hideDescription: Boolean,
    muteAlarm: Boolean,
    outlined: Boolean,
    flat: Boolean,
  },
  data() {
    return {
      item: null,
    }
  },
  watch: {
    itemId: {
      handler() {
        this.getItem()
      },
      deep: true,
    },
  },
  mounted() {
    this.getItem()
  },
  methods: {
    async getItem() {
      this.item = await db.images.where({ _id: this.itemId }).first()
      this.playAlarmIfNeeded()
    },

    playAlarmIfNeeded() {
      if (!this.item.data.alarm || this.muteAlarm) return
      new Audio("alarm3.mp3").play()
    },
    async switchAlarm(){
      this.item.data.alarm = !this.item.data.alarm
      this.playAlarmIfNeeded()
      db.images.put({...this.item})
    }
  },
  computed: {
    coordinates() {
      return this.$store.state.coordinates
    },
    imageSrc() {
      const urlCreator = window.URL || window.webkitURL
      return urlCreator.createObjectURL(this.item.imageBlob)
    },
  },
}
</script>
