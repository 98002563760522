<template>
  <v-card :loading="loading">
    <v-toolbar flat>
      <v-toolbar-title>{{ $t("Driving") }}</v-toolbar-title>
      <v-spacer />
      <!-- Only show the closest item when in fullscreen -->
      <!-- WARNING: do not show until loading complete -->
      <DriveFullScreenDialog
        v-if="!loading"
        :item="itemsSortedByDistance.slice(0, 1)[0]"
      />
    </v-toolbar>

    <template v-if="coordinates">
      <v-card-text>
        <v-expansion-panels accordion multiple>
          <!-- Filers -->
          <!-- TODO: prevent closing when selection -->
          <!-- TODO: own component? -->
          <v-expansion-panel>
            <v-expansion-panel-header>{{
              $t("Filters")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col>
                  <v-select
                    :items="courseNames"
                    v-model="filters.courseName"
                    :label="$t('Course name')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    :items="continuousFilterSelectItems"
                    v-model="filters.continuous"
                    :label="$t('Continuous')"
                  />
                </v-col>
              </v-row>

              <!-- Item count -->
              <!-- WARNING: Only show when done loading -->
              <v-row align="baseline">
                <v-col>
                  <v-icon>mdi-image</v-icon>
                  <span>x</span>
                  <v-progress-circular v-if="loading" indeterminate />
                  <span v-else>{{ items.length }}</span>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>{{ $t("Map") }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- Need to hide while loading because filteredItems accessed as computed here -->
              <v-progress-linear v-if="loading" indeterminate />
              <!-- Not very nice to pass displayRadius like that -->
              <ItemsMap
                v-else
                :displayRadius="settings.drive.displayRadius"
                :images="itemsWithCoordinates"
                :futurePosition="futurePosition"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-divider />
          <v-expansion-panel>
            <v-expansion-panel-header>{{
              $t("Settings")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row align="baseline">
                <v-col>
                  <!-- Problem: Thumb would overflow if shown -->
                  <!-- Note: Settings from mixin -->
                  <v-slider
                    class="mt-10"
                    prepend-icon="mdi-diameter-outline"
                    v-model="settings.drive.displayRadius"
                    step="1"
                    min="1"
                    max="100"
                    thumb-label="always"
                  >
                    <template v-slot:thumb-label="{ value }">
                      {{ value }}m
                    </template>
                  </v-slider>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>

      <!-- Need to hide while loading because filteredItems accessed as computed here -->
      <!-- Progress indicating loading items from IndexedDB -->
      <v-card-text v-if="loading">
        <v-row align="center">
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-progress-circular indeterminate />
          </v-col>
          <v-col cols="auto"> {{ $t("Loading images") }} </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-text>
      <NearbyItems :items="itemsSortedByDistance" v-if="!loading" />
    </template>

    <WaitingForGps v-else />

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark icon v-bind="attrs" @click="snackbar.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import DriveFullScreenDialog from "@/components/drive/DriveFullScreenDialog.vue"
import coordinateHelpers from "@/mixins/coordinateHelpers"
import NearbyItems from "@/components/drive/NearbyItems.vue"
import WaitingForGps from "../components/WaitingForGps.vue"
import ItemsMap from "../components/drive/ItemsMap.vue"
import settingsManagement from "../mixins/settingsManagement"
import { db } from "@/idb"

export default {
  name: "Driving",
  components: {
    DriveFullScreenDialog,
    NearbyItems,
    ItemsMap,
    WaitingForGps,
  },
  mixins: [coordinateHelpers, settingsManagement],
  data() {
    return {
      items: [],
      filters: {
        courseName: "any",
        continuous: "any",
      },
      courseNames: [
        { value: "any", text: this.$t("Any") },
        ...[...Array(10).keys()]
          .map((i) => `コース ${i + 1}`)
          .map((i) => ({ value: i, text: i })),
      ],
      continuousFilterSelectItems: [
        { value: "any", text: this.$t("Any") },
        { value: true, text: this.$t("With") },
        { value: false, text: this.$t("Without") },
      ],

      loading: false,
      snackbar: {
        show: false,
        text: null,
        color: undefined,
      },
    }
  },
  watch: {
    filters: {
      handler() {
        this.get_images_from_indexedDB()
      },
      deep: true,
    },
  },
  mounted() {
    this.get_images_from_indexedDB()
  },
  methods: {
    async get_images_from_indexedDB() {
      // WARNING: This loads a lot of data in memory
      this.loading = true
      try {
        this.items = []
        await db.images.each((image) => {
          // WARNING: Do not load images themselves in memory, just their metadata
          // Also, only take metadata that is strictly necessary
          const { data, _id } = image

          const {
            longitude,
            latitude,
            heading,
            courseName,
            continuousShootingMode,
          } = data

          // TODO: Do this for every filter using Object.keys
          // WARNING: continuous might be undefined
          if (
            this.filters.courseName !== "any" &&
            courseName !== this.filters.courseName
          ) {
            return
          }

          if (this.filters.continuous !== "any") {
            // TODO: improve
            if (!this.filters.continuous && continuousShootingMode) return
            if (this.filters.continuous && !continuousShootingMode) return
          }

          this.items.push({ _id, data: { longitude, latitude, heading } })
        })
      } catch (error) {
        console.error(error)
        this.snackbar.show = true
        this.snackbar.color = "error"
        this.snackbar.text = this.$t("Failed to load images")
      } finally {
        this.loading = false
      }
    },
  },
  computed: {
    coordinates() {
      return this.$store.state.coordinates
    },
    futurePosition() {
      if (!this.coordinates) return
      if (!this.coordinates.heading) return this.coordinates
      return this.getPredictedPosition(this.coordinates)
    },
    filteredItems() {
      if (!this.coordinates) return []
      return this.items.filter(({ data }) => {
        // Only items with cordinates
        if (!data.latitude || !data.longitude) return false

        // Only items within margin
        const distance = this.distanceBetweenCoordinates(
          data,
          this.futurePosition
        )
        if (distance > this.settings.drive.displayRadius) return false

        // Items with similar heading (if configured so)
        if (!this.settings.drive.filterHeading) return true

        const { heading: itemHeading } = data
        const { heading: currentHeading } = this.coordinates
        // If either the item or the user has no heading, just show the item
        if (!itemHeading || !currentHeading) return true

        const dHeadingCW = (currentHeading - itemHeading + 360) % 360
        const dHeadingCCW = (itemHeading - currentHeading + 360) % 360
        return Math.min(dHeadingCW, dHeadingCCW) < 90
      })
    },
    itemsWithCoordinates() {
      // Used for the map
      return this.items.filter(
        ({ data }) => !!data.latitude && !!data.longitude
      )
    },
    itemsSortedByDistance() {
      return this.filteredItems.slice().sort((a, b) => {
        const distanceToA = this.distanceBetweenCoordinates(
          a.data,
          this.coordinates
        )
        const distanceToB = this.distanceBetweenCoordinates(
          b.data,
          this.coordinates
        )
        return distanceToA - distanceToB
      })
    },
  },
}
</script>
