<template>
  <div>
    <template v-if="items.length">
      <v-card-text v-for="(item, index) of items" :key="index">
        <!-- Passing props like that is a bit inelegant -->
        <ItemCard
          :itemId="item._id"
          :hideDescription="hideDescription"
          :muteAlarm="muteAlarm"
        />
      </v-card-text>
    </template>
    <template v-else>
      <v-card-text class="text-center text-h6">
        {{ $t("No data available") }}
      </v-card-text>
    </template>
  </div>
</template>

<script>
import ItemCard from "@/components/drive/ItemCard.vue"

export default {
  name: "NearbyItems",
  components: {
    ItemCard,
  },
  props: {
    items: Array,
    hideDescription: Boolean,
    muteAlarm: Boolean,
  },
}
</script>
