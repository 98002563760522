<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>{{ $t("Camera") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-switch
        :label="$t('Continuous')"
        v-model="continuousShootingMode"
      ></v-switch>
    </v-toolbar>

    <!-- Warning: Those settings are only visible after starting continuous shooting -->
    <v-card-text v-if="continuousShootingMode">
      <v-row align="end">
        <v-col>
          <!-- Note: Settings from mixin -->
          <v-slider
            v-model.number="settings.capture.distanceBetweenContinuousCaptures"
            step="5"
            prepend-icon="mdi-map-marker-distance"
            thumb-label="always"
          >
            <template v-slot:thumb-label="{ value }"> {{ value }}m </template>
          </v-slider>
        </v-col>
        <v-col>
          <v-combobox
            :label="$t('Course name')"
            v-model="courseName"
            :items="courseNames"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-expansion-panels flat>
      <v-expansion-panel>
        <v-expansion-panel-header>{{ $t("Map") }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <ItemsMap />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <CameraControl
      v-if="coordinates"
      v-model="picture"
      :hidebtn="continuousShootingMode"
      ref="CameraControl"
    />

    <WaitingForGps v-else />

    <template v-if="picture && !continuousShootingMode">
      <v-card-text>
        <v-row>
          <v-col>
            <v-combobox
              :label="$t('Course name')"
              v-model="courseName"
              :items="courseNames"
            />
          </v-col>
          <v-col cols="auto">
            <v-switch prepend-icon="mdi-bullhorn" v-model="alarm"></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="description"
              :label="$t('Description')"
              auto-grow
              :rows="1"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text> </v-card-text>

      <v-card-text>
        <CapturedItemMap
          :captureCoordinates="captureCoordinates"
          @positionUpdate="updatePosition($event)"
        />
      </v-card-text>

      <v-card-text>
        <v-slider
          v-model="captureCoordinates.heading"
          :min="0"
          :max="360"
          thumb-label="always"
          step="1"
          prepend-icon="mdi-compass-outline"
        >
          <template v-slot:thumb-label="{ value }">
            {{ Math.round(value) }}
          </template>
        </v-slider>
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn @click="saveToIndexedDb()" :loading="uploading">
              <v-icon left>mdi-upload</v-icon>
              <span>Upload</span>
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-text>
    </template>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark icon v-bind="attrs" @click="snackbar.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import CameraControl from "@/components/capture/CameraControl.vue"
import CapturedItemMap from "../components/capture/CapturedItemMap.vue"
import WaitingForGps from "../components/WaitingForGps.vue"

import settingsManagement from "../mixins/settingsManagement"
import coordinateHelpers from "@/mixins/coordinateHelpers"

import ItemsMap from "@/components/drive/ItemsMap.vue"
import { db } from "@/idb"
import { v4 as uuidv4 } from "uuid"

export default {
  name: "CameraCapture",
  components: {
    ItemsMap,
    CameraControl,
    CapturedItemMap,
    WaitingForGps,
  },
  mixins: [coordinateHelpers, settingsManagement],
  data() {
    return {
      picture: null,
      open: false,
      description: "",
      courseName: "",
      uploading: false,
      snackbar: {
        show: false,
        text: null,
        color: undefined,
      },

      captureCoordinates: {},

      alarm: true,
      continuousShootingMode: false,
      prev_coordinates: null,

      distanceSinceLastCapture: 0,

      courseNames: [...Array(10).keys()].map((i) => `コース ${i + 1}`),
    }
  },
  watch: {
    picture(picture) {
      if (!picture) return

      this.captureCoordinates = {
        latitude: this.coordinates.latitude,
        longitude: this.coordinates.longitude,
        heading: this.coordinates.heading || 0,
        accuracy: this.coordinates.accuracy,
      }
    },
    coordinates: {
      handler: function (value) {
        if (!this.continuousShootingMode) return
        if (!this.coordinates) return
        if (!this.prev_coordinates) this.prev_coordinates = value

        this.distanceSinceLastCapture = this.distanceBetweenCoordinates(
          this.prev_coordinates,
          value
        )

        if (
          this.distanceSinceLastCapture >
          this.settings.capture.distanceBetweenContinuousCaptures
        ) {
          this.prev_coordinates = value
          this.takePicture()
        }
      },
      deep: true,
    },
  },

  methods: {
    generateMetadata() {
      const { longitude, latitude, accuracy, heading } = this.captureCoordinates

      return {
        longitude,
        latitude,
        heading,
        accuracy,
        description: this.continuousShootingMode ? "" : this.description,
        courseName: this.courseName,
        continuousShootingMode: this.continuousShootingMode,
        alarm: this.continuousShootingMode ? false : this.alarm,
      }
    },
    sleep: (delay) =>
      new Promise((resolve) => {
        setTimeout(resolve, delay)
      }),

    async saveToIndexedDb() {
      /*
      For reference, image schema:
      {
        file: { type: String, required: true, unique: true },
        time: { type: Date, default: Date.now },
        data: { type: Schema.Types.Mixed, default: {} },
      }
      */

      try {
        // Wait for comboboxes to blur
        await this.sleep(10)

        const item = {
          file: `${uuidv4()}.png`,
          time: new Date(),
          imageBlob: this.picture,
          data: this.generateMetadata(),
        }

        await db.images.add(item)

        this.picture = false

        this.snackbar.show = true
        this.snackbar.color = "green"
        this.snackbar.text = "Capture successful"
      } catch (error) {
        console.error(error)

        this.snackbar.show = true
        this.snackbar.color = "error"
        this.snackbar.text = "Capture failed"
      }
    },
    takePicture() {
      this.$refs.CameraControl.takePicture()
      // watchのpictureが実行されてからuploadを実行させる
      // TODO: remove delay because already above
      this.saveToIndexedDb()
    },

    wrapTo360(x) {
      // Might be unused
      if (x < 0) return x + 360
      else if (x >= 360) return x - 360
      else return x
    },
    updatePosition({ latitude, longitude }) {
      this.captureCoordinates.latitude = latitude
      this.captureCoordinates.longitude = longitude
    },
  },
  computed: {
    coordinates() {
      return this.$store.state.coordinates
    },
    captureFormattedCoordinates() {
      if (!this.captureCoordinates) return null
      return [
        this.captureCoordinates.longitude,
        this.captureCoordinates.latitude,
      ]
    },
    preview_src() {
      if (!this.picture) return
      const urlCreator = window.URL || window.webkitURL
      return urlCreator.createObjectURL(this.picture)
    },
  },
}
</script>

<style scoped>
.map {
  position: relative;
  height: 400px;
}

.tools {
  position: absolute;
  bottom: 1.5em;
  right: 1em;
  z-index: 4;
}
</style>
