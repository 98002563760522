<template>
  <vl-map
    style="height: 400px"
    class="map"
    :load-tiles-while-animating="true"
    :load-tiles-while-interacting="true"
  >
    <!-- Not using v-model because multiple -->
    <vl-view
      :zoom.sync="zoom"
      :center.sync="center"
      :rotation.sync="rotation"
    ></vl-view>

    <!-- Fixed overlay for map controls -->
    <v-card class="tools pa-2">
      <v-row justify="space-between" dense>
        <v-col cols="auto">
          <v-btn
            icon
            @click="followUser = !followUser"
            :color="followUser ? '#c00000' : undefined"
          >
            <v-icon>mdi-crosshairs-gps</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            icon
            @click="rotateWithUserHeading = !rotateWithUserHeading"
            :color="rotateWithUserHeading ? '#c00000' : undefined"
          >
            <v-icon>mdi-rotate-left</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <vl-layer-tile>
      <vl-source-osm></vl-source-osm>
    </vl-layer-tile>

    <template v-if="coordinates">
      <!-- User position makrer: red -->
      <vl-feature id="user-position">
        <!-- Point styled as icon -->
        <vl-geom-point :coordinates="userFormattedCoordinates"></vl-geom-point>
        <vl-style-box>
          <vl-style-icon
            :rotation="userMarker.rotation"
            :src="userMarker.src"
            :scale="0.5"
            :anchor="[0.5, 0.5]"
          />
        </vl-style-box>
      </vl-feature>

      <!-- Accuracy -->
      <vl-feature v-if="coordinates.accuracy">
        <vl-geom-circle
          :coordinates="userFormattedCoordinates"
          :radius="coordinates.accuracy"
        />
        <vl-style-box>
          <vl-style-fill color="#c0000011"></vl-style-fill>
          <vl-style-stroke color="#c0000044"></vl-style-stroke>
        </vl-style-box>
      </vl-feature>

      <!-- Margin, if provided-->
      <vl-feature v-if="displayRadius">
        <vl-geom-circle
          :coordinates="
            futurePosition
              ? formatCoordinates(futurePosition)
              : userFormattedCoordinates
          "
          :radius="displayRadius"
        />

        <!-- Changing color -->
        <vl-style-box>
          <vl-style-fill color="transparent"></vl-style-fill>
          <vl-style-stroke color="#c00000"></vl-style-stroke>
        </vl-style-box>
      </vl-feature>
    </template>

    <!-- Images position -->
    <vl-feature
      v-for="image in itemsSortedByDistanceToCenter.slice(0, maxItems)"
      :key="image._id"
      :id="image._id"
      :properties="image"
    >
      <vl-geom-point
        :coordinates="[image.data.longitude, image.data.latitude]"
      ></vl-geom-point>

      <vl-style-box>
        <vl-style-icon
          :src="imageMarker(image).src"
          :rotation="imageMarker(image).rotation"
          :scale="0.4"
          :anchor="[0.5, 0.5]"
        />
      </vl-style-box>
    </vl-feature>

    <!-- Display overlay on items that are clicked -->
    <vl-interaction-select
      :features.sync="selectedFeatures"
      :filter="interactionSelectFilter"
    >
      <!-- <template slot-scope="select" > -->
      <vl-overlay
        v-if="selectedFeature"
        :id="selectedFeature.id"
        :position="selectedFeature.geometry.coordinates"
        :auto-pan="true"
      >
        <v-card v-if="imagePreview" :to="imagePreview.to">
          <v-card-text>
            <v-img contain height="150" width="250" :src="imagePreview.src" />
          </v-card-text>
        </v-card>
      </vl-overlay>
      <!-- </template> -->
    </vl-interaction-select>
  </vl-map>
</template>

<script>
import coordinateHelpers from "@/mixins/coordinateHelpers"
import { db } from "@/idb"

export default {
  name: "ItemsMap",
  props: {
    displayRadius: Number,
    images: {
      type: Array,
      default() {
        return []
      },
    },
    futurePosition: undefined, // undefined because geocoordinates
  },
  mixins: [coordinateHelpers],
  data() {
    return {
      zoom: 17,
      center: [137.03291165964862, 34.98543171710549],
      rotation: 0,

      maxItems: this.$store.state.settings.drive.maxMapItems, // 50,

      // Keeping track of both because selectedFeatures results changes every iteration
      selectedFeatures: [],
      selectedFeature: null,

      imagePreview: null,

      followUser: true,
      rotateWithUserHeading: true,
    }
  },
  watch: {
    // Setting center and rotation in watcher and not computed because .sync
    coordinates: {
      handler(coordinates) {
        if (!coordinates) return
        if (this.followUser) this.center = this.userFormattedCoordinates
        if (this.rotateWithUserHeading) this.rotation = -this.userHeadingRad
      },
      deep: true,
    },
    followUser() {
      if (!this.coordinates) return
      if (this.followUser) this.center = this.userFormattedCoordinates
    },
    rotateWithUserHeading() {
      if (!this.coordinates) return
      if (this.rotateWithUserHeading) this.rotation = -this.userHeadingRad
    },
    selectedFeatures() {
      // Weird watcher because this.selectedFeatures triggers watch at every iteration
      const currentSelectedFeature = this.selectedFeatures[0]
      if (this.selectedFeature && !currentSelectedFeature) {
        this.selectedFeature = null
      } else if (
        !this.selectedFeature ||
        currentSelectedFeature.id !== this.selectedFeature.id
      ) {
        this.selectedFeature = currentSelectedFeature
        this.createPreviewImage()
      }
    },
  },
  methods: {
    imageMarker(image) {
      const { heading } = image.data

      let src = "/img/markers/marker_image_noheading.png"
      if (heading) src = "/img/markers/marker_image_heading_variant.png"

      // How much the marker of an image needs to be rotated
      // NOTE: The marker needs to rotate with the map, hence the +rotation
      // NOTE2: No direct relation to rotateWithUserHeading
      const headingRad = (Math.PI * heading) / 180.0
      let rotation = 0
      if (heading && heading !== "null") rotation = headingRad + this.rotation

      return { src, rotation }
    },

    goToUserPosition() {
      this.center = this.userFormattedCoordinates
    },
    interactionSelectFilter(vector) {
      return !!vector.values_._id
    },
    async createPreviewImage() {
      if (!this.selectedFeature) return
      const { id } = this.selectedFeature

      const urlCreator = window.URL || window.webkitURL
      const { imageBlob } = await db.images.where({ _id: id }).first()

      this.imagePreview = {
        src: urlCreator.createObjectURL(imageBlob),
        to: { name: "Item", params: { _id: id } },
      }
    },
    formatCoordinates({ latitude, longitude }) {
      return [longitude, latitude]
    },
  },

  computed: {
    coordinates() {
      return this.$store.state.coordinates
    },
    userMarker() {
      let src = "/img/markers/marker_user_noheading.png"
      if (this.coordinates && this.coordinates.heading)
        src = "/img/markers/marker_user_heading_variant.png"

      let rotation = this.rotation + this.userHeadingRad
      if (this.rotateWithUserHeading) rotation = 0

      return { src, rotation }
    },
    userFormattedCoordinates() {
      if (!this.coordinates) return null
      return [this.coordinates.longitude, this.coordinates.latitude]
    },
    userHeadingRad() {
      if (!this.coordinates) return 0
      const headingDegrees = this.coordinates.heading || 0 // Default to zero
      return (Math.PI * Number(headingDegrees)) / 180.0
    },
    imagesWithCoordinates() {
      // Removes images without coordinates
      return this.images.filter(({ data }) => data.latitude && data.longitude)
    },
    itemsSortedByDistanceToCenter() {
      const mapCenter = { latitude: this.center[1], longitude: this.center[0] }
      return this.imagesWithCoordinates.slice().sort((a, b) => {
        const distanceToA = this.distanceBetweenCoordinates(a.data, mapCenter)
        const distanceToB = this.distanceBetweenCoordinates(b.data, mapCenter)
        return distanceToA - distanceToB
      })
    },
  },
}
</script>

<style scoped>
.map {
  position: relative;
  height: 400px;
}

.tools {
  position: absolute;
  right: 1em;
  bottom: 1em;
  z-index: 4;
}
</style>
